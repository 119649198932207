import { clearStorageListener } from '@/utils/event/index';
import { Config } from '/@/constant/constant';
import { useRouter } from 'vue-router';
import { TaskExecutor } from '/@/executor/taskExecutor';
import { OnceExecutor } from '/@/executor/onceExecutor';
import { PageEnum } from '/@/enums/pageEnum';
import { setTimeoutExecs, throttle } from '/@/utils/limits';
import { MsgManager } from '/@/message/MsgManager';
import { LogManager } from '/@/message/LogManager';
import { MessageName } from '@/constant/constant';
import { handleReloadFn } from '@/utils/route';
import { message } from '@/utils/tooltips';
import { sleep } from '@/utils/http/axios/axiosRetry';

const REFRESH_TIME_INTERVAL = [100, 300, 500, 700, 900, 1000, 1250, 1500];

const listenMessage = (event) => {
  const router = useRouter();
  if (Config.RENDER_ENGINE_URL.includes(event.origin)) {
    const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
    if (data.message == 'verify_route') {
      if (data.action == 'load') {
        router.push(data.path);
      }
    }
  }
};

const beforeUnloadHandler = (event) => {
  // 记录单独打开的地址，然后再次跳转到此地址
  const path = window.location.hash.replace('#/', '/');
  if (path !== PageEnum.BASE_HOME && window.name !== 'frame') {
    const element = JSON.stringify({ time: new Date().getTime(), path });
    sessionStorage.setItem('ROUTE_PUSH_PATH', element);
  }
};

const pushToLastPath = (element, diff) => {
  const path = window.location.hash.replace('#/', '/');
  const targetPath = element.path.replace('#/', '/');
  const prefix = element.path.includes('/#') ? '' : '/#';
  if (diff < 1500 && path != targetPath) {
    window.location.href = window.origin + prefix + element.path;
  }
  sessionStorage.removeItem('ROUTE_PUSH_PATH');
};

const handleLoginCheck = () => {
  setTimeout(() => {
    const hash = window.location.hash.replace('#/', '/');
    const path = window.location.hash.replace('#/', '/').split('?')[0];
    if (path == '/login' && path != hash) {
      window.location.href = window.origin + '/#/login?_t=' + new Date().getTime();
    }
  }, 300);
};

const handleRouteRefresh = () => {
  const item = sessionStorage.getItem('ROUTE_PUSH_PATH');
  if (item && window.name !== 'frame') {
    const element = JSON.parse(item);
    const diff = new Date().getTime() - element.time;
    setTimeoutExecs(() => {
      pushToLastPath(element, diff);
    }, REFRESH_TIME_INTERVAL);
  }
  handleLoginCheck();
};

const handleResourceError = (event) => {
  const name = MessageName.RELOAD_FRAMEWORK;
  // 发版后 原hash值的js、css等资源不存在，导致路由跳转加载失败
  if (event?.target?.tagName === 'LINK') {
    LogManager.getInstance().info('全局监听异常 addEventListener error. 版本更新，link update');
    MsgManager.getInstance().sendMsg(name, { name, force: true, message: 'LINK' });
    handleReloadFn();
  } else if (event?.message?.includes('Failed to load module script')) {
    LogManager.getInstance().info('全局监听异常 addEventListener error. 版本更新, load module');
    MsgManager.getInstance().sendMsg(name, { name, force: true, message: event?.message });
    handleReloadFn();
  }
};

const handleLoadWorker = () => {
  if (window.self !== window.top) {
    return;
  }
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });

      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            // 监听状态变更
            installingWorker.onstatechange = async () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  LogManager.getInstance().record('Service worker, 新版本已经发布，页面将刷新...');
                } else {
                  LogManager.getInstance().record('Service worker, Content is cached...');
                }
              }
            };
          };
        })
        .catch((error) => {
          LogManager.getInstance().error('Error during service worker registration:', error);
        });
    });
  }
};

const handleLoadServiceWorkFn = throttle(handleLoadWorker, 10000);

/**
 * @description 全局Event管理器
 */
export class EventManager {
  private static instance: EventManager | undefined;

  constructor() {}

  // 获取Instance函数
  static getInstance(): EventManager {
    if (!EventManager.instance) {
      const instance = new EventManager();
      EventManager.instance = instance;
    }
    return EventManager.instance as EventManager;
  }

  // 注册所有Manager
  public register() {
    this.registerErrorHandle();
    this.registerLoadHandle();
    this.registerClearStorage();
    this.registerMessage();
    this.registerUnload();
    this.registerRouteRefresh();
    this.registerExecutor();
  }

  // 监听清空缓存操作
  public registerClearStorage() {
    window.addEventListener('storage', clearStorageListener);
  }

  public registerMessage() {
    window.addEventListener('message', listenMessage);
  }

  public registerUnload() {
    window.addEventListener('beforeunload', beforeUnloadHandler);
  }

  public registerRouteRefresh() {
    handleRouteRefresh();
  }

  public registerErrorHandle() {
    window.addEventListener('error', handleResourceError);
  }

  public registerLoadHandle() {
    handleLoadServiceWorkFn();
  }

  public registerExecutor() {
    if (window.self != window.top) {
      TaskExecutor.getInstance().start();
      OnceExecutor.getInstance().start();
    }
  }

  // 销毁所有Manager
  public destory() {
    this.destoryClearStorage();
    this.destoryMessage();
    this.destoryUnload();
    this.destoryExecutor();
  }

  // 注销监听清空缓存
  public destoryClearStorage() {
    window.removeEventListener('storage', clearStorageListener);
  }

  public destoryMessage() {
    window.removeEventListener('message', listenMessage);
  }

  public destoryUnload() {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  }

  public destoryExecutor() {
    // 清空线程执行
    TaskExecutor.getInstance().destroy();
    OnceExecutor.getInstance().destroy();
  }
}
