import { AesEncryption } from '/@/utils/cipher';
import { cacheCipher } from '/@/settings/encryptionSetting';

interface EncodeInterface {
  encode(text: string): void;
  decode(text: string): void;
}

export class EncodeManager implements EncodeInterface {
  private static instance: EncodeManager | undefined;
  private encryption: any;

  constructor() {
    const key = cacheCipher.key;
    const iv = cacheCipher.iv;
    this.encryption = new AesEncryption({ key, iv });
  }

  // 获取示例函数
  static getInstance() {
    if (!EncodeManager.instance) {
      const instance = new EncodeManager();
      EncodeManager.instance = instance;
    }
    return EncodeManager.instance;
  }

  encode(text: string) {
    try {
      const rspText = this.encryption.encryptByAES(text);
      return rspText;
    } catch {
      //
    }
  }

  decode(text: string) {
    try {
      const rspText = this.encryption.decryptByAES(text);
      return rspText;
    } catch {
      //
    }
  }
}
