/**
 * WeakMapManager 弱引用Map管理器，当引用不被调用时会快速被GC
 */
export class WeakMapManager {
  private keyMap: Map<string | number, any> | null;
  private valueMap: WeakMap<any, any> | null;

  constructor() {
    this.keyMap = new Map();
    this.valueMap = new WeakMap();
  }

  /**
   * 设置键值对
   * @param {string} key 键
   * @param {any} value 值
   */
  public set(key: string | number, value: any) {
    const keyObject = new String(key);
    this.keyMap?.set(key, keyObject);
    this.valueMap?.set(keyObject, value);
  }

  /**
   * 获取值
   * @param {string} key 键
   * @returns {any} 值
   */
  public get(key: string | number) {
    const keyObject = this.keyMap?.get(key);
    if (keyObject) {
      return this.valueMap?.get(keyObject);
    }
    return undefined;
  }

  /**
   * 判断值
   * @param {string} key 键
   * @returns {boolean} 是否存在
   */
  public has(key: string) {
    return this.keyMap?.has(key);
  }

  /**
   * 删除键值对
   * @param {string} key 键
   * @returns {boolean} 是否删除成功
   */
  public delete(key: string) {
    const keyObject = this.keyMap?.get(key);
    if (keyObject) {
      this.valueMap?.delete(keyObject);
      return this.keyMap?.delete(key);
    }
    return false;
  }

  /**
   * 清空Map内容
   */
  public clear() {
    this.keyMap?.clear();
    this.valueMap = new WeakMap();
  }

  /**
   * destory Map
   */
  public destory() {
    this.keyMap = null;
    this.valueMap = null;
  }

  /**
   * 遍历键值对
   * @param {Function} callback 回调函数
   */
  public forEach(callback: Function = () => {}) {
    this.keyMap?.forEach((keyObject, key) => {
      const value = this.valueMap?.get(keyObject);
      if (value !== undefined) {
        callback(value, key, this);
      }
    });
  }

  /**
   * 返回一个新数组，数组中的每一个元素都是回调函数的返回值
   * @param {Function} callback 回调函数
   * @returns {Array} 新数组
   */
  public map(callback: Function = () => {}) {
    const results: any[] = [] as any[];
    this.keyMap?.forEach((keyObject, key) => {
      const value = this.valueMap?.get(keyObject);
      if (value !== undefined) {
        const result = callback(value, key, this) || null;
        results.push(result);
      }
    });
    return results;
  }

  /**
   * 获取所有键
   * @returns {IterableIterator<string>}
   */
  public keys() {
    return this.keyMap?.keys();
  }

  /**
   * 获取所有值
   * @returns {IterableIterator<any>}
   */
  public values() {
    const values: any[] = [];
    this.keyMap?.forEach((keyObject) => {
      const value = this.valueMap?.get(keyObject);
      if (value !== undefined) {
        values.push(value);
      }
    });
    return values.values();
  }

  /**
   * 获取所有键值对
   * @returns {IterableIterator<[string, any]>}
   */
  public entries() {
    const entries: [string | number, any][] = [];
    this.keyMap?.forEach((keyObject, key) => {
      const value = this.valueMap?.get(keyObject);
      if (value !== undefined) {
        entries.push([key, value]);
      }
    });
    return entries.values();
  }

  /**
   * 获取存储的键值对数量
   * @returns {number} 存储的键值对数量
   */
  public getSize() {
    return this.keyMap?.size ?? 0;
  }
}
