// 时间间隔枚举
export enum TimeInterval {
  ONE_SECOND = 1000 * 1,
  FIVE_SECOND = 1000 * 5,
  TEN_SECOND = 1000 * 10,
  HALF_MINUTE = 60000 * 0.5,
  ONE_MINUTE = 60000 * 1,
  FIVE_MINUTE = 60000 * 5,
  TEN_MINUTE = 60000 * 10,
  HALF_HOUR = 1800000 * 1,
  ONE_HOUR = 3600000 * 1,
  TWO_HOUR = 3600000 * 2,
  THREE_HOUR = 3600000 * 3,
  FOUR_HOUR = 3600000 * 4,
  FIVE_HOUR = 3600000 * 5,
  TEN_HOUR = 3600000 * 10,
}

// 锁名称
export enum Lock {
  LoginLock = 'LoginLock',
  PageRouteLock = 'PageRouteLock',
  LogoutLock = 'LogoutLock',
  RequestLock = 'RequestLock',
  ThreadLock = 'THREAD_LOCK',
}

// 常用常量
export enum ComConstant {
  ScreenDateSet = 'screen-date-set__',
  ReloadTime = 'RELOAD_TIME__',
}

export enum MessageName {
  RELOAD_FRAMEWORK = 'RELOAD_FRAMEWORK',
  RELOAD_TIME = 'RELOAD_TIME',
  FRAMEWORK_PUBLISH_TIME = 'FRAMEWORK_PUBLISH_TIME',
}

export enum StoreName {
  CACHE_STORE = 'cache-store',
  MEM_STORE = 'mem-store',
  SYS_STORE = 'sys-store',
  LOG_STORE = 'log-store',
  REC_STORE = 'rec-store',
  DATA_STORE = 'data-store',
}

export enum TaskName {
  ExecTodoTask = 'EXEC_TODO_TASK',
  LOAD_BUNDLE_TASK = 'LOAD_BUNDLE_TASK',
}

// 配置信息
export const Config = {
  RENDER_ENGINE_URL: 'http://139.9.148.32:9090/',
};

// 附件预览枚举
export enum FILE_PREVIEW {
  PREVIEW_DOMAIN = 'http://122.9.154.245',
  PREVIEW_PORT = '8012',
  DOWNLOAD_DOMAIN = 'http://139.9.148.32',
  DOWNLOAD_PORT = '48080',
  FILE_DOMAIN = 'http://192.168.0.200',
  FILE_PORT = '48080',
  IMAGE_TYPES = '[".jpg",".jpeg",".png",".gif",".bmp",".ico",".jfif",".webp"]',
}

// 大屏白名单路由
export const ScreenList = [
  ['/#/frame/cockpit/overview', '/#/cockpitoverview'],
  ['/#/frame/cockpit/operation', '/#/cockpitoperation'],
  ['/#/frame/cockpit/property', '/#/cockpitproperty'],
  ['/#/frame/cockpit/security', '/#/cockpitsecurity'],
  ['/#/frame/cockpit/composition', '/#/cockpitcomposition'],
  ['/#/frame/cockpit/power', '/#/cockpitpower'],
  ['/#/frame/groupOperation', '/#/groupoperation'],
  ['/#/frame/stationOperation', '/#/stationoperation'],
];

// 大屏白名单路由
export const ScreenRouteList = ScreenList.map((item) => item[1].slice(2));

// 附件预览图片类型
export const PICTURE_TYPES = JSON.parse(FILE_PREVIEW.IMAGE_TYPES);
