<template>
  <ConfigProvider :locale="getAntdLocale" :autoInsertSpaceInButton="false">
    <AppProvider>
      <teleport to="body">
        <div class="modal-mask" v-if="visible" @mousewheel.prevent>
          <div class="modal-mask-container" @mousewheel.prevent></div>
        </div>
      </teleport>
      <RouterView v-slot="{ Component, route }">
        <template v-if="handleRoute(route)">
          <keep-alive ref="keepAliveRef" :include="keepAliveKeys" :exclude="excludeKeys" :max="30">
            <component
              ref="componentRef"
              :is="formatCompInstance(Component, route.fullPath)"
              :key="handleRouteKey(route, Component)"
              :name="handleRouteKey(route, Component)"
            />
          </keep-alive>
        </template>
        <template v-else>
          <component
            :is="Component"
            :key="handleRouteKey(route, Component)"
            :name="handleRouteKey(route, Component)"
          />
        </template>
      </RouterView>
    </AppProvider>
  </ConfigProvider>
</template>

<script lang="ts" setup>
  import { onMounted, onUnmounted, nextTick, ref, h } from 'vue';
  import { ConfigProvider } from 'ant-design-vue';
  import { AppProvider } from '@/components/Application';
  import { useTitle } from '@/hooks/web/useTitle';
  import { useLocale } from '@/locales/useLocale';
  import { listenThemeMessage } from '@/utils/theme';
  import { useRouter } from 'vue-router';
  import { PageEnum } from '/@/enums/pageEnum';
  import { closeCurrentTab, handleReloadFn } from '@/utils/route';
  import { EventManager } from '@/message/EventManager';
  import { SysMessage } from '/@/hooks/web/useMessage';
  import { MsgManager } from '/@/message/MsgManager';
  import { LogManager } from '/@/message/LogManager';
  import { MessageName } from '@/constant/constant';
  import { WeakMapManager } from '/@/message/WeakMapManager';
  import 'dayjs/locale/zh-cn';

  const { getAntdLocale } = useLocale();
  const router = useRouter();
  const componentRef = ref<any>();
  const visible = ref<boolean | null>(false);
  const keepAliveKeys = ref<string[] | null>([]);
  const excludeKeys = ref<string[] | null>([]);
  const keepAliveRef = ref<any>();
  const keepAliveMap = new WeakMapManager();
  const wrapperMap = new WeakMapManager();

  useTitle();

  // 处理route函数
  const handleRoute = (route) => {
    return route.meta.keepAlive;
  };

  const formatCompInstance = (component: any, path: string) => {
    let wrapper;
    let result;
    if (wrapperMap.has(path)) {
      wrapper = wrapperMap.get(path);
      return wrapper;
    } else {
      wrapper = {
        name: path,
        render() {
          return h(component);
        },
      };
      result = h(wrapper);
      wrapperMap.set(path, result);
      return result;
    }
  };

  // 处理route函数
  const handleRouteKey = (route, component) => {
    const myComp = formatCompInstance(component, route.fullPath);
    if (myComp?.type?.name) {
      addKeepAliveKey(route.fullPath);
      keepAliveMap.set(route.fullPath, myComp?.type?.name);
    } else if (component?.type?.__name) {
      addKeepAliveKey(route.fullPath);
      keepAliveMap.set(route.fullPath, component?.type?.__name);
    }
    return route.fullPath;
  };

  // 示例：添加组件到 keep-alive 缓存
  const addKeepAliveKey = (key: string) => {
    const isInIFrame = checkInIframe();
    if (!keepAliveKeys.value.includes(key) && isInIFrame) {
      keepAliveKeys.value.push(key);
    }
  };

  // 示例：从 keep-alive 缓存中移除组件
  const removeKeepAliveKey = (key: string) => {
    const kaValue = keepAliveMap.get(key) as string;
    const index = keepAliveKeys.value.indexOf(kaValue);
    if (index !== -1) {
      keepAliveKeys.value.splice(index, 1);
      excludeKeys.value.push(key);
      wrapperMap.delete(key);
      keepAliveRef.value;
    }
  };

  // 处理路由路径函数
  const handleRoutePath = () => {
    try {
      const flag = checkInIframe();
      const hashFlag = window.location.hash && window.location.hash.startsWith('#');
      let routePath = hashFlag ? window.location.hash.slice(1) : window.location.hash;
      if (routePath && routePath.includes('?')) {
        routePath = routePath.split('?')[0];
      }
      if (flag) {
        let iframePath = window.frameElement.src.split('/#')[1];
        if (iframePath && iframePath.includes('?')) {
          iframePath = iframePath.split('?')[0];
        }
        const baseFlag = iframePath == PageEnum.BASE_HOME || iframePath == PageEnum.BASE_HOME + '/';
        const routeFlag = routePath == PageEnum.BASE_HOME || routePath == PageEnum.BASE_HOME + '/';
        const loginFlag =
          iframePath == PageEnum.BASE_LOGIN || iframePath == PageEnum.BASE_LOGIN + '/';
        if (iframePath !== routePath && !baseFlag) {
          LogManager.getInstance().info('app vue page router push:', iframePath);
          router.push(iframePath as string);
        } else if (baseFlag) {
          SysMessage.getInstance().warning('警告：页签页面不能打开框架页面！');
          nextTick(closeCurrentTab);
        } else if (routeFlag) {
          SysMessage.getInstance().warning('警告：页签页面不能打开框架页面...');
          nextTick(closeCurrentTab);
        } else if (loginFlag) {
          SysMessage.getInstance().warning('警告：跳转异常，请稍后重试...');
        }
      }
    } catch (error) {
      //
    }
  };

  // 判断当前页面是否在 iframe 中显示
  const checkInIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  // 处理 IframePage 函数
  const handleIframePage = () => {
    setTimeout(() => {
      handleRoutePath();
    }, 10);
  };

  // 处理路由变化函数
  const handleRouteChange = (message) => {
    try {
      if (checkInIframe()) {
        LogManager.getInstance().info('handleRouteChange:', new Date().getTime());
        let { url, array } = message;
        url = url.replace('/#/', '/');
        router.push(url as string);
        wrapperMap.forEach((element, key) => {
          const flag = array.includes(key);
          if (!flag) {
            wrapperMap.delete(key);
          }
        });
      }
    } catch (error) {
      LogManager.getInstance().error('route change error:', error);
    }
  };

  // 销毁函数
  const destory = () => {
    componentRef.value = null;
    keepAliveRef.value = null;
    visible.value = null;
    keepAliveKeys.value = null;
    excludeKeys.value = null;
    keepAliveMap.destory();
    wrapperMap.destory();
  };

  onMounted(() => {
    listenThemeMessage();
    handleIframePage();

    // 监听check-iframe-framepage消息
    MsgManager.getInstance().listen('check-iframe-framepage', () => {
      handleIframePage();
    });

    // 监听是否退出登录
    MsgManager.getInstance().listen('logouting', (message) => {
      SysMessage.logouting = message;
    });

    if (checkInIframe()) {
      MsgManager.getInstance().listen('iframe-url-change', (message) => {
        handleRouteChange(message);
      });
      MsgManager.getInstance().listen('remove-keepalive-key', (message) => {
        removeKeepAliveKey(message.key as string);
      });
    } else {
      MsgManager.getInstance().listen(MessageName.RELOAD_FRAMEWORK, handleReloadFn);
    }

    // 打印启动日志
    LogManager.getInstance().info('app is start...');

    // 注册事件监听器
    EventManager.getInstance().register();
  });

  onUnmounted(() => {
    destory();
    EventManager.getInstance().destory(); // 监听清空缓存操作
  });
</script>
<style lang="less" scoped>
  .modal-mask {
    display: flex;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 15%);

    .modal-mask-container {
      display: flex;
      position: absolute;
      z-index: 1000;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 15%);
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
