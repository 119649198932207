import { nextTick, Ref } from 'vue';
import { useMultipleTabStore } from '/@/store/modules/multipleTab';
import { useRouter } from 'vue-router';
import { buildUUID } from '/@/utils/uuid';
import { MsgManager } from '/@/message/MsgManager';
import { useGo } from '/@/hooks/web/usePage';
import { useUserStore } from '/@/store/modules/user';
import { useThrottleFn } from '@vueuse/core';
import { PageEnum } from '/@/enums/pageEnum';
import { SysMessage } from '/@/hooks/web/useMessage';
import { getLoginTimeDiff } from '/@/utils/common';
import { LockManager } from '/@/message/LockManager';
import { LogManager } from '/@/message/LogManager';
import { sleep } from '@/utils/http/axios/axiosRetry';
import { ComConstant, TimeInterval, MessageName } from '/@/constant/constant';
import { EncodeManager } from '/@/message/EncodeManager';
import { CacheManager } from '/@/message/CacheManager';
import { throttle } from '@/utils/limits';
import { WeakMapManager } from '/@/message/WeakMapManager';

// 驾驶舱专用大屏穿透路由跳转（带设置的开始结束时间）
export const handleRouteChange = (url: string, extraParams: any) => {
  const key = ComConstant.ScreenDateSet + (extraParams?.from || '');
  if (sessionStorage.getItem(key)) {
    const params = JSON.parse(sessionStorage.getItem(key) as string);
    if (Object.keys(extraParams).length) {
      Object.assign(params, extraParams);
    }
    addTabPage(url, url, params);
  } else {
    addTabPage(url, url, extraParams);
    LogManager.getInstance().info('no screen-data-set and can not turn into new page.');
  }
};

// window 通过新窗口打开网页
export const winOpenUrl = async (path: string, name: string) => {
  try {
    await LockManager.getInstance().acquireLock('WIN_OPEN_LOCK');
    const wname = name.startsWith('/') ? name : window.encodeURIComponent(name);
    const sign = path.includes('?') ? '&' : '?';
    const prefix = path.startsWith('/#') ? '' : '/#';
    const url =
      window.origin + prefix + path + sign + '__name__=' + window.encodeURIComponent(wname);
    const argmentPath = path + sign + '__name__=' + window.encodeURIComponent(wname);
    const element = JSON.stringify({ time: new Date().getTime(), path: argmentPath });
    if (path === PageEnum.BASE_HOME) {
      await sessionStorage.setItem('ROUTE_PUSH_PATH', element);
    }
    // 执行打开网页
    if (url.includes(wname)) {
      const newName = EncodeManager.getInstance().encode(argmentPath);
      window.open(url, newName);
    } else {
      window.open(url, wname);
    }
  } catch (error) {
    //
  } finally {
    await sleep(1500);
    if (path === PageEnum.BASE_HOME) {
      sessionStorage.removeItem('ROUTE_PUSH_PATH');
    }
    await LockManager.getInstance().releaseLock('WIN_OPEN_LOCK');
  }
};

// 解析路由路径参数
export const parseRoutePath = (path: string): Record<string, string> => {
  const segments = path.split('/');
  const lastSegment = segments[segments.length - 1];
  const replacedEquals = lastSegment.replace(/__/g, '='); // 替换 __ 为 =
  const replacedAmpersand = replacedEquals.replace(/!!/g, '&'); // 替换 !! 为 &
  const replacedUnderscore = replacedAmpersand.replace(/_aa_/g, '&'); // 替换 _aa_ 为 &
  const queryParams: Record<string, string> = {}; // 解析为对象
  const pairs = replacedUnderscore.split('&');
  for (const pair of pairs) {
    const [key, value] = pair.split('=');
    queryParams[key] = value;
  }
  return queryParams;
};

// 跳转路由不带缓存
export const pushAndRefresh = (path: string) => {
  const router = useRouter();
  const useTab = useMultipleTabStore();
  useTab.setRefreshList(path); // 设置刷新时效300ms, 在此1000ms内，打开此path路由对应页面，都重新渲染，不加载缓存
  router.push(path);
};

// 获取节点名称
export const generateNameMap = (tree = [], menuNameMap: any) => {
  menuNameMap = menuNameMap ? menuNameMap : new WeakMapManager();
  try {
    for (const node of tree) {
      const path = node?.url.includes('?') ? node?.url.split('?')[0] : node?.url;
      menuNameMap.set(path, node?.name);
      if (node?.children && node?.children.length > 0) {
        generateNameMap(node?.children, menuNameMap);
      }
    }
  } catch (error) {
    //
  }
  return menuNameMap;
};

// 将路径转为带参数url
export const pathToUrl = (path, params) => {
  const hasQuery = path.includes('?');
  const paramString = Object.entries(params)
    .map(([key, value]) => {
      if (typeof value === 'undefined') {
        return `${encodeURIComponent(key)}=_undef_`;
      } else if (value === true) {
        return `${encodeURIComponent(key)}=_true_`;
      } else if (value === false) {
        return `${encodeURIComponent(key)}=_false_`;
      } else if (value === null) {
        return `${encodeURIComponent(key)}=_nul_`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`;
      }
    })
    .join('&');
  const result = hasQuery ? `${path}&${paramString}` : `${path}?${paramString}`;
  return result;
};

// 将带参数的url反解析为路径和参数对象
export const urlToPath = (url?) => {
  const flag = window.location.hash && window.location.hash.startsWith('#');
  const originPath = url ? url : flag ? window.location.hash.slice(1) : window.location.pathname;
  const [path, queryString] = originPath.split('?');
  let params: any = {};
  try {
    if (queryString) {
      const paramPairs = queryString.split('&');
      params = handleParseParams(paramPairs);
    } else {
      const burl = Array.from(window.parent.document.getElementsByTagName('iframe')).filter(
        (e) => e.classList[0] == 'active',
      )[0].src;
      const paramPairs = burl.split('?')[1].split('&');
      params = handleParseParams(paramPairs);
    }
  } catch (error) {
    //
  }
  return { path, params };
};

// 解析URL参数函数
const handleParseParams = (paramPairs) => {
  try {
    if (typeof paramPairs === 'undefined' || paramPairs === null || paramPairs === '') {
      return {};
    }
    const params = {};
    paramPairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      const decodedKey = decodeURIComponent(key);
      if (value === '_undef_') {
        params[decodedKey] = undefined;
      } else if (value === '_true_') {
        params[decodedKey] = true;
      } else if (value === '_false_') {
        params[decodedKey] = false;
      } else if (value === '_nul_') {
        params[decodedKey] = null;
      } else {
        params[decodedKey] = decodeURIComponent(value);
      }
    });
    return params;
  } catch (error) {
    return {};
  }
};

// 获取当前页面信息
export const getCurrentPageInfo = () => {
  return { id: '', path: '', params: '' };
};

// 新增TabPage页面函数（iframe模式）
export const addTabPage = (
  path: string,
  name: string | null | undefined = '',
  params: Object | null = null,
) => {
  const id = buildUUID();
  try {
    const userStore = useUserStore();
    const prename = name;
    const nameMap = userStore.getMenuNameMap as Map<string, any>;
    const purePath = path.includes('?') ? path.split('?')[0] : path;
    const isNameEmpty = name === '' || name?.startsWith('/') || typeof name === 'undefined';
    name = isNameEmpty ? nameMap.get(purePath) : name;
    path.replace('/framepage', PageEnum.BASE_HOME);
    path = path.startsWith(PageEnum.BASE_HOME) ? path : PageEnum.BASE_HOME + path;
    path = params == null || typeof params == 'undefined' ? path : pathToUrl(path, params);
    const message = { type: 'addTabPage', data: { id, path, name, params } };
    if (window.self !== window.top || window.location.hash === `#${PageEnum.BASE_HOME}`) {
      LogManager.getInstance().info('add tab page:', new Date().getTime());
      sendMessage(message);
    } else {
      const oldname = prename || '';
      const pname = oldname === '' || !oldname?.startsWith('/') ? path : oldname;
      winOpenUrl(path, pname);
    }
  } catch (error) {
    //
  }
  return id;
};

// 新增Tab页签并关闭原页面（iframe模式）
export const addTabAndClose = (
  path: string,
  name: string | null | undefined = '',
  params: Object | null = null,
  closeID: string = '',
  refresh: boolean = true,
) => {
  const id = buildUUID();
  try {
    LogManager.getInstance().info('addTabAndClose:', new Date().getTime());
    const userStore = useUserStore();
    const nameMap = userStore.getMenuNameMap as Map<string, any>;
    const purePath = path.includes('?') ? path.split('?')[0] : path;
    name = name == '' ? nameMap.get(purePath) : name;
    path.replace('/framepage', PageEnum.BASE_HOME);
    path = path.startsWith(PageEnum.BASE_HOME) ? path : PageEnum.BASE_HOME + path;
    path = params == null || typeof params == 'undefined' ? path : pathToUrl(path, params);
    const message = { type: 'addTabAndClose', data: { id, path, name, params, closeID, refresh } };
    sendMessage(message);
  } catch (error) {
    //
  }
  return id;
};

// 关闭TabPage页面函数（iframe模式）
export const closeTabPage = (path: string) => {
  const message = { type: 'closeTabPage', data: { path } };
  sendMessage(message);
};

// 关闭当前Tab页签函数（iframe模式）
export const closeCurrentTab = () => {
  const message = { type: 'closeCurrentTab', data: {} };
  sendMessage(message);
};

// 关闭当前Tab页签函数（iframe模式）
export const closeTabById = (id) => {
  const message = { type: 'closeTabById', data: { id } };
  sendMessage(message);
};

// 刷新TabPage页面函数（iframe模式）
export const reloadCurrrentTab = () => {
  const message = { type: 'refreshTabPage', data: {} };
  sendMessage(message);
};

// 刷新指定TabPage页面函数（iframe模式）
export const reloadTabById = (id) => {
  const message = { type: 'reloadTabById', data: { id } };
  sendMessage(message);
};

// 处理路由
export const handleRouteGo = () => {
  const go = useGo();
  const { path } = urlToPath();
  if (![PageEnum.BASE_HOME, PageEnum.BASE_LOGIN, PageEnum.BASE_LOGOUT].includes(path)) {
    go(path);
  }
};

// 推送消息至框架
export const sendMessage = (message) => {
  MsgManager.getInstance().sendMsg('iframe-tabs-message', message);
};

// 推送下线消息函数
export const sendOfflineMessage = () => {
  const { path } = urlToPath();
  if (path != PageEnum.BASE_LOGIN) {
    const message = { type: 'userOffline' };
    if (window.top == window) {
      handleOfflineMessage(message);
    }
    MsgManager.getInstance().sendMsg('notify-message', message);
  }
};

// 用户下线消息处理函数
export const handleOfflineMessageFn = (message) => {
  const userStore = useUserStore();
  const diff = getLoginTimeDiff();
  LogManager.getInstance().info('exec handleOfflineMessageFn:', new Date().getTime());
  // 登录上锁的情况下，不能执行操作
  if (SysMessage.loginLock) {
    return;
  }
  // diff 间隔值 小于 10000 时，不能执行退出
  if (diff <= 10000) {
    return;
  }
  if (message.type === 'userOffline') {
    userStore.logout(true);
  }
};

// 处理框架Reload函数
export const handleFrameReload = async (message) => {
  try {
    const nowTime = new Date().getTime();
    const rValue = window?.top?.location?.hash?.split('?')[0];
    if (rValue == '#/frame' && window?.top?.location) {
      prefetchLink('index.js');
      LogManager.getInstance().record('new version is published and reload...');
      await sleep(1500);
      window.top.location.href = `${window.location.origin}/#/frame?_timestamp=${nowTime}`;
      if (message === true || message?.isReload === true) {
        LogManager.getInstance().record('new version is published and isReload is true...');
        window.top.location.reload();
      }
    }
  } catch (error) {
    LogManager.getInstance().error('frame reload error:', error);
  }
};

// 处理重新加载
export const handleReloadFn = throttle(async (message) => {
  const time = (await CacheManager.getInstance().getCache(ComConstant.ReloadTime)) as number;
  const lastTime = time ?? 0;
  const nowTime = new Date().getTime();
  if (nowTime - lastTime > TimeInterval.TEN_MINUTE && window.location.hostname !== 'localhost') {
    CacheManager.getInstance().setCache(ComConstant.ReloadTime, nowTime);
    LogManager.getInstance().record('new version is published and system is reloaded.', message);
    await sleep(1000);
    handleFrameReload(message);
  }
}, TimeInterval.TEN_MINUTE);

// OffLine操作进行防抖处理
export const handleOfflineMessage = useThrottleFn((message) => {
  LogManager.getInstance().info('exec handleOfflineMessage:', new Date().getTime());
  handleOfflineMessageFn(message);
}, 5000);

// 监听用户下线消息
export const listenOfflineMessage = () => {
  MsgManager.getInstance().listen('notify-message', handleOfflineMessage);
};

// 处理Reload函数
export const handleInstanceUpdated = (instance, callback = () => {}) => {
  if (instance?.proxy) {
    nextTick(() => {
      callback();
      instance?.proxy.$forceUpdate();
    });
  } else {
    nextTick(() => {
      // window.location.hostname === 'localhost' ? null : window.location.reload();
    });
  }
};

// 预加载Link函数
export const prefetchLink = (file) => {
  const link = document.createElement('link');
  link.rel = 'prefetch';
  link.href = '/assets/' + file + '?_t=' + buildUUID();
  document.head.appendChild(link);
};

// 加载bundle信息
export const fetchBundle = () => {
  fetch('/bundle.json?_t=' + buildUUID())
    .then((response) => response.json())
    .then(async (data) => {
      try {
        if (idbKeyval && typeof idbKeyval === 'object') {
          if (Reflect.has(data, 'files')) {
            for (let file of data?.files) {
              try {
                if (file.endsWith('.js') || file.endsWith('.css')) {
                  const isFileExist = await idbKeyval.get(file);
                  if (!isFileExist || file === 'index.js') {
                    await sleep(10);
                    prefetchLink(file);
                    if (idbKeyval && Reflect.has(idbKeyval, 'set')) {
                      idbKeyval.set(file, 'Y');
                    }
                  }
                }
              } catch (error) {
                LogManager.getInstance().error('prefetch data file error:', error);
              }
            }
          }
          if (Reflect.has(data, 'datetime')) {
            const publishedLastTime = await idbKeyval.get(MessageName.FRAMEWORK_PUBLISH_TIME);
            if (publishedLastTime && publishedLastTime != data.datetime) {
              LogManager.getInstance().record('Bundle工具，检测到版本更新，请执行版本更新操作！');
              prefetchLink('index.js');
              LogManager.getInstance().record('new bundle version is published, reloading...');
              idbKeyval.set(MessageName.FRAMEWORK_PUBLISH_TIME, data.datetime);
              handleReloadFn(true);
            }
            if (!publishedLastTime && data.datetime) {
              idbKeyval.set(MessageName.FRAMEWORK_PUBLISH_TIME, data.datetime);
            }
          }
        }
      } catch (error) {
        LogManager.getInstance().error('prefetch data files error:', error);
      }
    })
    .catch((error) => {
      LogManager.getInstance().error('Error loading bundled files:', error);
    });
};

// 加载bundle文件函数
export const handleLoadBundle = () => {
  fetch('/mainfest.json?_t=' + buildUUID())
    .then((response) => response.json())
    .then(async (data) => {
      try {
        if (Reflect.has(data, 'datetime')) {
          const publishedLastTime = await idbKeyval.get(MessageName.FRAMEWORK_PUBLISH_TIME);
          if (publishedLastTime && publishedLastTime != data.datetime) {
            fetchBundle();
          }
          if (!publishedLastTime && data.datetime) {
            idbKeyval.set(MessageName.FRAMEWORK_PUBLISH_TIME, data.datetime);
          }
        }
      } catch (error) {
        //
      }
    })
    .catch(() => {
      fetchBundle();
    });
};

export const handlePageClassName = (pageClassName: Ref) => {
  if (window.self === window.top) {
    pageClassName.value = 'page-container'
  }
};

export const handlePageAddOYHidden = () => {
  const dom = document.querySelector('html') as HTMLHtmlElement;
  if (dom) {
    dom.classList.add('overflow-y-hidden');
  }
};

export const handlePageRemoveOYHidden = () => {
  const dom = document.querySelector('html') as HTMLHtmlElement;
  if (dom) {
    dom.classList.remove('overflow-y-hidden');
  }
};
