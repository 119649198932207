import * as ProcessInstanceApi from '@/api/bpm/processInstance';
import { addTabPage } from '@/utils/route';
import { useUserStore } from '/@/store/modules/user';
import { useI18n } from '@/hooks/web/useI18n';

export const toUnderline = (str) => {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase();
};

export const toFlowPage = async (bizId, flowName) => {
  const { t } = useI18n();
  const flowData = await ProcessInstanceApi.getFlowData(bizId);
  if (flowData) {
    const tabName = getTabName(
      flowData.status,
      flowName || flowData.name,
      flowData.startUserId,
      flowData.isHandle,
    );
    const isReadOnly =
      tabName.indexOf(t('common.action.view')) != -1 ||
      tabName.indexOf(t('common.action.approval')) != -1
        ? 'true'
        : 'false';
    addTabPage(flowData.viewPath, tabName, {
      id: bizId,
      processInstanceId: flowData.processInstanceId,
      page_readonly: isReadOnly,
    });
  }
};

export const getTabName = (status, flowName, startUserId, isHandle) => {
  const { t } = useI18n();
  let tabName = t('common.action.view');
  const userStore = useUserStore();
  const userId = userStore.getUserInfo?.userId;
  const isEqual = userId == startUserId ? true : false;
  if (status == 0 && isEqual) {
    tabName = t('common.action.edit');
  } else if (status == 1 && isHandle > 0) {
    tabName = t('common.action.approval');
  }
  return tabName + flowName;
};

export const getLoginTimeDiff = () => {
  try {
    const time = localStorage.getItem('LOGIN_TIMESTAMP');
    const nowtime = new Date().getTime();
    const timestamp = Number(time || 0);
    const diff = nowtime - timestamp;
    return diff;
  } catch {
    return -1;
  }
};

// 函数：将对象还原为空对象 Reactive对象清空 Object对象清空
export const resetObj = (obj) => {
  Object.keys(obj).forEach((key) => {
    delete obj[key];
  });
};

// 函数：将来源对象赋值给目标对象 Reactive对象赋值 Object对象赋值
export const assignObj = (target, source) => {
  Object.keys(source).forEach((key) => {
    target[key] = source[key];
  });
};

// 防抖函数
export const throttle = (func, limit) => {
  let lastTime = 0;
  return async function (...args) {
    const now = Date.now();
    if (now - lastTime > limit) {
      await func(...args);
      lastTime = now;
    }
  };
};

// 数组去重
export const deduplicate = (arr: Array<any>, fieldName: string = 'key') => {
  const keySet = new Set();
  const uniqueArray = [] as any[];
  for (const obj of arr) {
    if (Reflect.has(obj, fieldName)) {
      if (!keySet.has(obj[fieldName])) {
        keySet.add(obj[fieldName]);
        uniqueArray.push(obj as any);
      }
    }
  }
  return uniqueArray;
};
